var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:{
    'card--flex-toolbar-xs': _vm.$vuetify.breakpoint.xsOnly,
    'card--flex-toolbar': _vm.$vuetify.breakpoint.smAndUp
  }},[_c('v-toolbar',{staticClass:"elevation-3",attrs:{"flat":"","dense":"","color":"secondary"}},[_c('v-toolbar-title',{staticClass:"body-2 white--text"},[_vm._v(_vm._s(this.$route.meta.subtitle))]),_c('v-spacer'),_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","fab":"","dark":"","color":"primary"},on:{"click":_vm.reload}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("autorenew")])],1),_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","fab":"","dark":"","color":"accent","to":{
        path:
          '/userroleunitedit/-1/' +
          (this.$route.params.parent + '/' + this.$route.params.pid)
      }}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("add")])],1)],1),_c('v-card-text',{class:{ 'pa-0': _vm.$vuetify.breakpoint.xsOnly }},[_c('v-container',{staticClass:"pt-0",class:{ 'pa-0': _vm.$vuetify.breakpoint.xsOnly },attrs:{"fluid":"","fill-height":""}},[_c('v-row',{attrs:{"no-gutters":"","align-space-around":"","justify-center":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('InteractiveTable',{ref:"userRoleUnitList",staticClass:"lg-table",attrs:{"headers":[
              { text: 'ID', value: 'id' },
              { text: 'Ime', value: 'first_name' },
              { text: 'Prezime', value: 'last_name' },
              { text: 'Uloga', value: 'role_name' },
              { text: 'Organizacijska jedinica', value: 'unit_name' },
              { text: 'Članarinu plaća', value: 'membership_private'},
              { text: 'Vrijedi od', value: 'valid_from' },
              { text: 'Vrijedi do', value: 'valid_to' }
            ],"apiLoad":"userroleunit/userroleunits.php","actions":[
              {
                id: '1',
                name: 'Uredi korisnika',
                path: '/useredit/#user#',
                icon: 'edit',
                color: 'secondary'
              }
            ],"defaultFilter":"last_name","queryFilter":this.$route.params,"dateFilter":"","defaultSort":"last_name"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }